.socialIcon {
    text-decoration: none;
    color: white;
    font-size: 1.35rem;
    opacity: 0.6;
    transition: ease-in-out 0.4s;
  }
  
  .socialIcon:hover {
  
    color: #61dafb;
    opacity: 1;
  }