.card {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: white;
  padding: 0.8rem 0;
  transition: 0.5s;
}

.card:hover {
  cursor: pointer;
  border-bottom: 0.1px solid white;
}

.collapsible-card {
  width: 100%;
  box-shadow: 0 4px 4px 0 ;
  padding: 0px 0px;
  overflow: hidden;
}

.header {
  width: 100%;
}
.content {
  transition: height 0.4s ease;
  overflow: hidden;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

