.App {
  text-align: center;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.parent {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 1rem;
}

.hi {
  text-align: left;
  font-size: 1.1rem;

  color: white;
}

.heading {
  margin: 0;
  font-size: 1.2rem;
  color: white;
  font-weight: normal;
  margin-top: 1rem;
  text-align: left;
  opacity: 0.8;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .App {
    flex-direction: column;
  }

  .parent {
    width: 85%;
  }

  /* .nav {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-top: 3vh;
  }

  .links {
    flex-direction: column;
    gap: 10px;
  } */
}


