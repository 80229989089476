/* LinktreePage.css */
.linktree-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 85vh;
    width: 100%;
    max-width: 100%;
    padding: 2rem 1rem;
  }
  
  .linktree-header {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .linktree-header h1 {
    font-size: 2.8rem;
    font-weight: bold;
    margin: 0.5rem 0;
    color: white;
    background: linear-gradient(90deg, #4a68b1, #7892dc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .bio {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.1rem;
    margin-top: 0.5rem;
    max-width: 600px;
  }
  
  .project-description {
    margin: 1rem 0 2rem 0;
    color: rgba(255, 255, 255, 0.7);
    max-width: 600px;
    text-align: left;
  }
  
  .project-description p {
    margin-bottom: 0.5rem;
  }
  
  .project-description ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .project-description li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .project-description li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #4a68b1;
  }
  
  .links-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    gap: 1rem;
  }
  
  .link-box {
    background-color: rgba(30, 30, 30, 0.7);
    color: white;
    padding: 1.2rem;
    text-align: center;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
  }
  
  .link-box:hover {
    background-color: rgba(40, 40, 40, 0.9);
    transform: translateY(-2px);
    border-color: rgba(74, 104, 177, 0.5);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .linktree-footer {
    margin-top: -2rem;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.9rem;
    text-align: center;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .linktree-header h1 {
      font-size: 2.2rem;
    }
    
    .link-box {
      padding: 1rem;
    }
    
    .project-description {
      padding: 0 1rem;
    }
  }