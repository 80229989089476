.nav {
    display: flex;
    justify-content: space-between;
    font-size: larger;
    gap: 1rem;
    width: 100%;
    padding: 10px 0;
    color: white;
    margin-top: 5vh;
  }
  
  .hr {
    border: 0.8px dashed whitesmoke;
    width: 100%;
  }

  .links{
    display: flex;
    gap: 20px;
    color: inherit;
  }
  
  @media (max-width: 700px) {
  
  
  .nav {
      font-size: large;
      gap: 0.8rem;
    }
    
    
  }
  